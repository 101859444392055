<template>
  <div>
    <template>
      <div class="orderBtn">
        <div
          v-if="
            (getOrderKey(orderState) === 'PAY' ||
              (orderData.status === 0 &&
                getOrderKey(orderState) !== 'SALE_DEAL')) &&
            type === 3 &&
            orderData.is_password == 0
          "
          class="btn-item bg"
          @click.stop="selectPay"
        >
          立即提交
        </div>
        <div
          v-if="
            (getOrderKey(orderState) === 'PAY' ||
              (orderData.status === 0 &&
                getOrderKey(orderState) !== 'SALE_DEAL')) &&
            type !== 3
          "
          class="btn-item bg"
          @click.stop="selectPay"
        >
          立即{{ tripartite("VUE_APP_TABEN") ? "兑换" : "支付" }}
        </div>
        <div
          v-if="
            getOrderKey(orderState) === 'PENDING' ||
            getOrderKey(orderState) === 'PAY' ||
            (orderData.status === 0 && getOrderKey(orderState) !== 'SALE_DEAL')
          "
          class="btn-item"
          @click.stop="cancelOrder()"
        >
          取消订单
        </div>
        <div
          v-if="getOrderKey(orderState) === 'EVALUATE'"
          class="btn-item bg"
          @click.stop="evaluation()"
        >
          评价
        </div>
        <div
          v-if="
            ifMore &&
            (getOrderKey(orderState) === 'EVALUATE_ED' ||
              getOrderKey(orderState) === 'NO_SALE')
          "
          class="btn-item bg"
          @click="toCommentDetails"
        >
          查看评价
        </div>
        <div
          v-if="getOrderKey(orderState) === 'CLOSE'"
          class="btn-item"
          @click.stop="delOrder()"
        >
          删除订单
        </div>
        <div
          v-if="
            getOrderKey(orderState) === 'EVALUATE_ED' ||
            getOrderKey(orderState) === 'NO_SALE'
          "
          class="btn-item"
          @click.stop="buyAgain()"
        >
          再次{{ tripartite("VUE_APP_TABEN") ? "兑换" : "购买" }}
        </div>
        <div
          v-if="ifMore && getOrderKey(orderState) === 'DELIVER'"
          class="btn-item bg"
          @click.stop="buyAgain()"
        >
          再次{{ tripartite("VUE_APP_TABEN") ? "兑换" : "购买" }}
        </div>
        <div
          v-if="
            ifMore &&
            (getOrderKey(orderState) === 'EVALUATE' ||
              getOrderKey(orderState) === 'CLOSE')
          "
          class="btn-item"
          @click.stop="buyAgain()"
        >
          再次{{ tripartite("VUE_APP_TABEN") ? "兑换" : "购买" }}
        </div>
        <div
          v-if="!ifMore && getOrderKey(orderState) === 'DELIVER'"
          class="btn-item"
          @click.stop="buyAgain()"
        >
          再次{{ tripartite("VUE_APP_TABEN") ? "兑换" : "购买" }}
        </div>
        <div
          v-if="!ifMore && getOrderKey(orderState) === 'EVALUATE_ED'"
          class="btn-item"
          @click="toCommentDetails"
        >
          查看评价
        </div>
        <div
          v-if="getOrderKey(orderState) === 'TAKE'"
          class="btn-item bg"
          @click.stop="confirmReceipt"
        >
          确认收货
        </div>
        <div
          v-if="
            ifMore &&
            ((getOrderKey(orderState) === 'DELIVER' &&
              Number(orderData.type) !== 6) ||
              getOrderKey(orderState) === 'TAKE') &&
            Number(orderData.is_refund) === 1
          "
          class="btn-item"
          @click.stop="applySale()"
        >
          申请售后
        </div>
        <div
          v-if="
            ifMore &&
            (getOrderKey(orderState) === 'DELIVER' ||
              getOrderKey(orderState) === 'TAKE')
          "
          class="btn-item"
          @click.stop="openChat"
        >
          联系客服
        </div>
        <div
          v-if="getOrderKey(orderState) === 'NO_SALE'"
          class="btn-item"
          @click.stop="delOrder()"
        >
          删除订单
        </div>
        <div
          v-if="getOrderKey(orderState) === 'GROUP_WAIT'"
          class="btn-item bg"
        >
          立即邀请
        </div>
        <div
          v-if="getOrderKey(orderState) === 'SALE_DEAL'"
          class="btn-item"
          @click.stop="cancelApply()"
        >
          取消申请
        </div>
        <div
          v-if="
            getOrderKey(orderState) === 'SALE_ING' &&
            Number(orderData.status) === 2
          "
          class="btn-item bg"
          @click.stop="show_fill = true"
        >
          填写物流
        </div>
        <div
          v-if="
            getOrderKey(orderState) === 'SALE_ING' ||
            getOrderKey(orderState) === 'SALE_FINISH' ||
            getOrderKey(orderState) === 'SALE_REFUSE'
          "
          class="btn-item"
          @click.stop="openChat"
        >
          联系客服
        </div>
        <confirm-alert
          :value="show_toast"
          :is_delete="true"
          :confirmText="confirmText"
          confirmBtnText="确认取消"
          @cancel="cancel"
          @confirm="confirm"
        ></confirm-alert>

        <!--昆山支付弹窗-->
        <confirm-alert
          :value="kunshan_toast"
          :is_delete="false"
          :confirmText="kunshanTxt"
          cancelBtnText="取消支付"
          confirmBtnText="确认支付"
          @cancel="kunshan_toast = false"
          @confirm="payOrder(orderData.pay_order_id, 123)"
        ></confirm-alert>
        <!--昆山支付弹窗-->

        <!--没有支付密码弹窗-->
        <ConfirmAlert
          :value="no_pwd"
          :is_delete="false"
          confirmText="抱歉，您还没有设置支付密码"
          confirmBtnText="去设置"
          @cancel="no_pwd = false"
          @confirm="confirmPwd"
        ></ConfirmAlert>
        <!--没有支付密码弹窗-->

        <!--卡券-->
        <div v-if="show_card_pay">
          <card_pay @cardPayOrder="cardPayOrder"></card_pay>
        </div>
        <!--卡券-->

        <!--安全键盘-->
        <yd-keyboard
          v-model="show_keyboard"
          :callback="checkPwd"
          disorder
          ref="keyboard"
          title
        >
        </yd-keyboard>
        <!--安全键盘-->

        <FillLogistics
          :id="orderData.id"
          :value="show_fill"
          @cancel="show_fill = false"
          @submitFill="submitFill"
        ></FillLogistics>

        <div v-html="icbcHtml"></div>
      </div>
    </template>

    <template>
      <div
        v-if="sermob_my == true"
        class="PopUp"
        :class="callRouter == '/order_list' ? 'padding' : ''"
      >
        <div class="bot-mobile" @click.stop="openChat">
          <img src="~@/assets/ic_back_.png" @click.stop="closeCall" />
          <span class="service-phone">
            客服电话:
            <span>{{ service_mobile }}</span>
          </span>
          <span class="dial">
            <a :href="'tel:' + service_mobile">确认呼叫</a>
          </span>
          <span class="cancel" @click.stop="closeCall">取消</span>
        </div>
      </div>

      <!-- 饭卡支付弹窗 -->
      <yd-popup position="bottom" v-model="is_pay" @click.native="noPay()">
        <div class="info-item">
          <yd-input
            slot="right"
            v-model="mobile"
            required
            :show-success-icon="false"
            :show-error-icon="false"
            placeholder="请输入饭卡卡号"
          ></yd-input>
        </div>
        <div class="info-item">
          <yd-input
            slot="right"
            type="password"
            v-model="password"
            required
            :show-success-icon="false"
            :show-error-icon="false"
            placeholder="请输入饭卡密码"
          ></yd-input>
        </div>
        <div style="margin: 0.2rem 0">
          <yd-button
            v-if="confirmStaus"
            class="pay-sty"
            @click.native="goPay()"
            bgcolor="#409eff"
            >确认</yd-button
          >
          <yd-button v-else class="pay-sty" bgcolor="#409eff">确认</yd-button>
        </div>
      </yd-popup>
      <!-- 饭卡支付弹窗 -->
    </template>
  </div>
</template>
<script>
import { orderMixin } from "@/mixins/orderMixin.js";
import {
  cancelOrder,
  cancelAfterSale,
  payOrder,
  confirmReceipt,
  editRefund,
  delOrder,
  cardPayOrder,
  getPayInfo,
} from "@/services/orderApi.js";
import ConfirmAlert from "@/components/common/ConfirmAlert";
import card_pay from "@/components/order/card_pay";
import FillLogistics from "@/components/common/FillLogistics";
import "@/utils/qiyu"; //七鱼
import { wxShare, randomWord } from "../../utils/common";
import { debounce } from "@/utils/debounce";
import { baseUrl } from "../../utils/env";
import { mapState } from "vuex";
import md5 from "js-md5";
import setRsaPass from "@/utils/jsencrypt";
import { ServicePhone } from "@/services";
import {
  tripartite,
  zjgKeepAlive,
  payBack,
  customAccount,
} from "../../utils/custom";

export default {
  name: "orderBtn",
  mixins: [orderMixin],
  inject: ["reload"],
  props: {
    orderState: {
      // 订单状态
      type: [Number, String],
      default: 1,
    },
    ifMore: {
      // 是否展示全部的订单按钮
      type: Boolean,
      default: true,
    },
    orderData: {
      // 订单数据
      type: Object,
      default: () => {},
    },
    orderType: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      businessId: (state) => state.businessInfo.id,
      type: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      pay_password: (state) => !!state.userInfo.pay_password, //是否设置支付密码
    }),
  },
  components: {
    ConfirmAlert,
    FillLogistics,
    card_pay,
  },
  data() {
    return {
      show_toast: false,
      alertType: 1, // 弹窗的类型： 1：取消订单  2：取消申请售后
      confirmText: "您确定要取消订单么？",
      kunshanTxt: "", // 昆山支付框文案
      kunshan_toast: false, // 昆山支付框显隐
      no_pwd: false, //是否设置支付密码
      show_keyboard: false, //支付密码键盘
      show_fill: false, //填写物流
      is_request: false, //是否在请求中
      show_card_pay: false, //卡券支付弹窗
      sermob_my: false, //电话弹窗判断
      service_status: "", //是否开启客服
      service_mobile: "", //客服电话号
      callRouter: "",
      icbcHtml: null, //工行支付文本
      is_pay: false, //是否饭卡支付
      mobile: "", //饭卡号
      password: "", //密码
      confirmStaus: true, // 确认请求状态
      isJyFl: false, //江阴福利
      zhThrottle: false, // 中行节流
    };
  },
  //路由改变jssdk参数重新赋值
  beforeRouteEnter(to, from, next) {
    next();
    wxShare();
  },
  methods: {
    // 拓本商城
    tripartite,

    //江阴福利
    isjy() {
      if (customAccount("VUE_APP_JY")) {
        this.isJyFl = true;
      }
    },

    // 关闭电话弹出
    closeCall() {
      this.sermob_my = false;
      console.log(this.sermob_my);
    },

    // 评价
    evaluation() {
      this.$router.push({
        path: "/evaluation",
        query: {
          id: this.orderData.id,
          img: this.orderData.order_items[0].base_pic,
        },
      });
    },

    // 申请售后
    applySale() {
      this.$router.replace({
        path: "/sale-list",
        query: {
          orderState: this.orderState,
          order_id: this.orderData.id,
          orderType: this.orderType,
        },
      });
    },

    // 删除订单
    async delOrder() {
      let id = this.orderData.id
        ? this.orderData.id
        : this.$route.query.order_id
        ? this.$route.query.order_id
        : "";
      try {
        const res = await delOrder(id);
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "删除订单成功",
            timeout: 1500,
            icon: "none",
            callback: () => {
              if (
                this.$route.name == "order_detail" ||
                this.$route.name == "order_collect"
              ) {
                this.$router.go(-1);
              } else {
                this.reload();
              }
            },
          });
        }
      } catch (e) {}
    },

    // 再次购买
    buyAgain() {
      let goods_list = this.orderData.item_list || this.orderData.order_items;
      let goods = goods_list[0];
      let activity_id = "";
      if (customAccount("VUE_APP_ZHONGHANG")) {
        activity_id = goods.activity_id;
      } else {
        activity_id = undefined;
      }
      this.$router.push({
        path: "/goods-detail",
        query: {
          item_id: goods.item_id,
          activity_id: activity_id,
        },
      });
    },

    async confirm() {
      zjgKeepAlive();
      try {
        if (this.alertType == 1) {
          if (this.confirmStaus) {
            this.confirmStaus = false;
            const startRandom = randomWord(false, 4);
            const endRandom = randomWord(false, 4);
            const sign = `${startRandom}pay_order_id:${this.$route.query.pay_order_id}${endRandom}`;
            const params = {
              pay_order_id: this.$route.query.pay_order_id,
              sign: md5(sign),
              random: `${startRandom}${endRandom}`,
              mobile: true,
            };
            let data = await cancelOrder(params);
            this.confirmStaus = true;
            if (data.code === 0) {
              this.$dialog.toast({
                mes: "取消处理中",
                timeout: 1500,
                icon: "none",
                callback: () => {
                  this.$router.go(-1);
                },
              });
            } else {
              console.log("取消失败", data);
              this.cancel();
              this.$dialog.toast({
                mes: data.msg,
                timeout: 1500,
                icon: "error",
              });
            }
          }
        } else if (this.alertType == 2) {
          if (this.confirmStaus) {
            this.confirmStaus = false;
            const startRandom = randomWord(false, 4);
            const endRandom = randomWord(false, 4);
            const sign = `${startRandom}id:${this.orderData.id}${endRandom}`;
            const params = {
              id: this.orderData.id,
              sign: md5(sign),
              random: `${startRandom}${endRandom}`,
              mobile: true,
            };
            let data = await cancelAfterSale(params);
            this.confirmStaus = true;
            if (data.code === 0) {
              this.$dialog.toast({
                mes: "取消成功",
                timeout: 1500,
                icon: "none",
                callback: () => {
                  if (this.$route.name == "order_detail") {
                    this.$router.go(-1);
                  } else {
                    this.reload();
                  }
                },
              });
            } else {
              console.log("取消失败", data);
              this.cancel();
              this.$dialog.toast({
                mes: data.msg,
                timeout: 1500,
                icon: "error",
              });
            }
          }
        }
      } catch (err) {
        throw err;
      }
    },
    cancel() {
      this.show_toast = false;
      // console.log(this.orderState,"orderState")
    },
    // 取消订单
    cancelOrder() {
      zjgKeepAlive();
      if (this.$route.name == "order-collect") {
        this.alertType = 1;
        this.confirmText = "您确定要取消订单么？";
        this.show_toast = true;
      } else {
        this.$router.push({
          path: "/order-collect",
          query: {
            pay_order_id: this.orderData.pay_order_id,
          },
        });
      }
    },
    //填写物流信息
    async submitFill(item) {
      let params = item;
      try {
        const res = await editRefund(params);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.show_fill = false;
          this.$dialog.toast({
            mes: "提交成功",
            timeout: 1500,
            icon: "none",
            callback: () => {
              if (this.$route.name == "order_detail") {
                this.$router.go(-1);
              } else {
                this.reload();
              }
            },
          });
        } else {
          console.log("提交失败", res);
          this.show_fill = false;
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error",
          });
        }
      } catch (e) {
        throw e;
      }
    },
    // 取消申请
    cancelApply() {
      this.alertType = 2;
      this.confirmText = "您确定要取消申请么？";
      this.show_toast = true;
    },
    //解决点穿问题
    noPay() {
      event.stopPropagation();
    },

    //饭卡支付
    goPay: debounce(async function () {
      this.confirmStaus = false;
      window.scroll(0, -1);
      if (this.password && this.mobile) {
        let password = /^[0-9a-zA-Z]*$/g.test(this.password);
        let mobile = /^[0-9a-zA-Z]*$/g.test(this.mobile);
        if (!password || !mobile) {
          this.$dialog.toast(
            {
              mes: "仅允许输入数字或字母,请重新输入!",
            },
            600
          );
          this.mobile = "";
          this.password = "";
          return;
        }
        let params = {
          pay_order_id: this.pay_order_id,
          password: this.password,
          mobile: this.mobile,
        };
        const res = await cardPayOrder(params);
        if (res.code != 0) {
          this.$dialog.toast(
            {
              mes: res.msg,
            },
            300
          );
          this.is_pay = false;
        } else {
          this.$router.replace({
            name: "pay_success",
            query: {
              id: this.pay_order_id,
              money: this.together_price,
              group_id: this.group_id ? this.group_id : "",
            },
          });
          this.is_pay = false;
        }
      } else {
        this.$dialog.toast(
          {
            mes: "请填写账号密码",
          },
          300
        );
        this.is_pay = false;
      }
      setTimeout(() => {
        this.confirmStaus = true;
      }, 2000);
    }, 100),

    //支付
    selectPay() {
      // 1现金，2余额，3积分
      let id = this.orderData.pay_order_id
        ? this.orderData.pay_order_id
        : this.$route.query.pay_order_id
        ? this.$route.query.pay_order_id
        : "";
      this.pay_order_id = id;
      let payway = this.orderData.payway
        ? Number.parseInt(this.orderData.payway)
        : this.orderData.pay_way
        ? Number.parseInt(this.orderData.pay_way)
        : 1;
      if (payway == 1 && this.type == 3) {
        payway = "";
      }
      let wxTotal = Number.parseFloat(this.orderData.total_wx);
      let intTotal = Number.parseFloat(this.orderData.total_integral);
      console.log("payway" + payway);

      if (payway === 5) {
        this.toShowCardPay();
      } else if (
        payway === 3 &&
        (this.businessId == 2311 || this.businessId == 2265) &&
        wxTotal == 0
      ) {
        this.$dialog.confirm({
          title: "请核对您的支付信息",
          mes: `您将消耗：${this.orderData.total_integral}积分
					</br>`,
          opts: [
            {
              txt: "取消",
              color: "#353535",
            },
            {
              txt: "确定",
              color: true,
              callback: async () => {
                this.payOrder(this.pay_order_id, "");
              },
            },
          ],
        });
        return;
      } else if (payway === 20) {
        this.is_pay = true;
      } else if (payway === 21) {
        console.log(this.orderData.pay_order_sn);
        this.callPaymentControl(this.orderData.pay_order_sn);
      } else if (payway === 10 && wxTotal == 0 && intTotal > 0) {
        // 昆山存积分支付(直接弹窗确认形式)
        this.kunshanTxt = `该订单需要支付${intTotal}积分`;
        this.kunshan_toast = true;
      } else if (wxTotal == 0 && intTotal > 0 && payway !== 16) {
        if (
          !this.pay_password &&
          (this.businessId != 2311 || this.businessId != 2265)
        ) {
          //未设置支付密码
          this.no_pwd = true;
          return;
        } else if (this.pay_password) {
          //输入支付密码
          this.toPayPwd();
        }
      } else if (
        payway === 1 ||
        payway === 10 ||
        payway === 15 ||
        payway === 16
      ) {
        this.payOrder(id);
      } else if (payway === 11 || payway === 12) {
        this.jpkPay(payway);
      } else {
        if (wxTotal > 0) {
          this.payOrder(id);
        } else {
          if (
            !this.pay_password &&
            (this.businessId != 2311 || this.businessId != 2265)
          ) {
            //未设置支付密码
            this.no_pwd = true;
            return;
          } else if (this.pay_password) {
            //输入支付密码
            this.toPayPwd();
          }
        }
      }
    },

    //立即支付
    async payOrder(id, val) {
      zjgKeepAlive();

      let bid = window.localStorage.getItem("bid");
      let group_id = this.orderData.group_buy_open_id
        ? this.orderData.group_buy_open_id
        : "";
      let money = this.orderData.pay_amount
        ? this.orderData.pay_amount
        : this.orderData.total_amount
        ? this.orderData.total_amount
        : "";
      const self = this;
      this.kunshan_toast = false;
      try {
        if (id) {
          const startRandom = randomWord(false, 4);
          const endRandom = randomWord(false, 4);
          const sign = `${startRandom}pay_order_id:${id}${endRandom}`;
          const params = {
            pay_order_id: id,
            password: this.isJyFl ? setRsaPass(val) : val,
            sign: md5(sign),
            random: `${startRandom}${endRandom}`,
            mobile: true,
          };
          const res = await payOrder(params);
          if (res.code === 0) {
            const data = res.data;
            let wxTotal = Number.parseFloat(this.orderData.total_wx);

            //特殊积分商城支付无需密码
            if (
              (this.businessId == 2311 || this.businessId == 2265) &&
              this.orderData.payway == 3 &&
              wxTotal == 0
            ) {
              self.$dialog.loading.close();
              window.location.replace(
                `${baseUrl}/?bid=${bid}#/pay_success?id=${id}&money=0`
              );
            } else if (!val) {
              console.log("微信支付");
              wx.ready(function () {
                wx.chooseWXPay({
                  timestamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                  nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
                  package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                  signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                  paySign: data.paySign, // 支付签名
                  success: function (res) {
                    // this.toPay()
                    console.log(res);
                    window.location.replace(
                      `${baseUrl}/?bid=${bid}#/pay_success?id=${id}&money=${money}&group_id=${group_id}`
                    );
                  },
                  fail: function (res) {
                    console.log(res);
                  },
                });
              });
            } else {
              self.$dialog.loading.close();
              window.location.replace(
                `${baseUrl}/?bid=${bid}#/pay_success?id=${id}&money=0`
              );
            }
          } else if (res.code === 2) {
            this.icbcHtml = res.data.split("<script>")[0];
            this.$nextTick(() => {
              document.forms[0].submit();
            });
          } else if (res.code === 10) {
            // 昆山农商行回调
            window.location.href = `${process.env.VUE_APP_KUNSHANURL}${res.data.trade_no}`;
          } else if (res.code === 13) {
            // 拓本成功跳转
            window.location.href = res.data.url;
          } else if (res.code === 16) {
            payBack(res.code, id, res.data.url);
          } else {
            if (val) {
              self.$dialog.loading.close();
              self.$refs.keyboard.$emit("ydui.keyboard.error", res.msg);
            } else {
              this.$dialog.toast({
                mes: res.msg,
                timeout: 1500,
                icon: "error",
              });
            }
          }
        }
      } catch (e) {}
    },

    toShowCardPay() {
      this.show_card_pay = true;
    },

    //  特殊卡券支付
    async cardPayOrder(item) {
      let group_id = this.orderData.group_buy_open_id
        ? this.orderData.group_buy_open_id
        : "";
      let money = this.orderData.pay_amount
        ? this.orderData.pay_amount
        : this.orderData.total_amount
        ? this.orderData.total_amount
        : "";
      let params = {
        pay_order_id: this.pay_order_id,
        card_no: item.card_no,
        password: item.card_password,
      };
      console.log(params);
      try {
        const res = await cardPayOrder(params);
        if (res.code === 0) {
          this.show_card_pay = false;
          window.location.replace(
            `${baseUrl}/?bid=${bid}#/pay_success?id=${this.pay_order_id}&money=${money}&group_id=${group_id}`
          );
        } else {
          this.$dialog.toast({
            mes: res.msg,
          });
        }
      } catch (e) {}
    },
    //去设置支付密码
    confirmPwd() {
      this.$router.push({
        name: "verify_phone",
        query: {
          function_type: 1,
        },
      });
    },
    //支付密码
    toPayPwd() {
      this.show_keyboard = true;
    },
    //  验证支付密码
    checkPwd(val) {
      let self = this;
      // console.log("输入的密码是：" + val + " - 无序");
      self.$dialog.loading.open("验证支付密码");
      self.payOrder(self.pay_order_id, md5(val));
    },
    //  确认收货
    async confirmReceipt() {
      let id = this.orderData.id
        ? this.orderData.id
        : this.$route.query.order_id
        ? this.$route.query.order_id
        : "";
      try {
        if (id) {
          const startRandom = randomWord(false, 4);
          const endRandom = randomWord(false, 4);
          const sign = `${startRandom}order_id:${id}${endRandom}`;
          const params = {
            order_id: id,
            sign: md5(sign),
            random: `${startRandom}${endRandom}`,
            mobile: true,
          };
          const res = await confirmReceipt(params);
          if (res.code === 0) {
            const data = res.data;
            console.log(data);
            this.$dialog.toast({
              mes: "确认收货成功",
            });
            this.$router.push({
              name: "confirm_success",
            });
          } else {
            this.$dialog.toast({
              mes: res.msg,
            });
          }
        }
      } catch (e) {}
    },
    // 中行调起支付控件
    async callPaymentControl(order_sn) {
      try {
        // 中行节流
        if (this.zhThrottle) {
          return false;
        }
        this.zhThrottle = true;
        let self = this;
        let res = await getPayInfo(order_sn);
        if (res.code === 0) {
          let setting = res.data.data;
          window.c_plugins.merchantBridge.callPaymentControl(
            function (data) {
              self.zhThrottle = false;
              // 下述内容为点击左上角<后执行
              if (data.isCancelPay === "1") {
                // 客户取消了支付
              } else {
                if (data.orderStatus === "1") {
                  // 支付成功的回调方法，可写返回后逻辑
                  self.is_request = false;
                  console.log("res我是回调成功了", res);
                  self.$router.replace({
                    name: "pay_success",
                    query: {
                      id: self.pay_order_id,
                      money: self.together_price,
                      group_id: self.group_id ? self.group_id : "",
                    },
                  });
                } else {
                  // 支付失败的回调方法 ，可写返回后逻辑
                  self.is_request = false;
                  self.$router.replace({
                    name: "order-collect",
                    query: {
                      pay_order_id: self.pay_order_id,
                      haveDone: 0,
                    },
                  });
                }
              }
            },
            function (err) {
              alert(err.message || err || "网络错误，请检查网络连接");
            },
            setting
          );
        } else {
          self.$dialog.toast({
            mes: "唤起支付异常，请再次尝试",
            timeout: 500,
          });
        }
      } catch (e) {}
    },
    //查看评价
    toCommentDetails() {
      let id = this.orderData.id
        ? this.orderData.id
        : this.$route.query.order_id
        ? this.$route.query.order_id
        : "";
      this.$router.push({
        name: "comment_details",
        query: {
          order_id: id,
        },
      });
    },
    //客服按钮弹窗状态获取
    async openchated() {
      try {
        let res = await ServicePhone();
        let { data } = res;
        // console.log(data.service_status);
        if (res.code == 0) {
          // console.log(res);
          this.service_status = data.service_status; //开启
          this.service_mobile = data.service_mobile; //关闭
        }
      } catch (err) {
        throw err;
      }
    },
    //客服聊天
    openChat() {
      if (this.service_status == 1) {
        var isSdkReady = false;
        ysf("onready", function () {
          isSdkReady = true;
        });
        if (isSdkReady) {
          location.href = ysf("url");
        }
        let self = this;
        ysf("config", {
          uid: self.orderData.user_id,
          unconfirm: 1,
          data: JSON.stringify(self.orderData),
          success: function () {
            // 成功回调
            ysf("open");
          },
          error: function () {
            // 错误回调
            // handle error
          },
        });
      } else {
        this.sermob_my = true;
      }
    },

    // 鲸品库支付
    jpkPay(payway) {
      let way = payway === 11 ? 1 : 2;
      document.location = `yqtjs://h5_goods_pay?order_title=${this.orderData.order_title}&order_no=${this.orderData.pay_order_id}
							&pay_order_sn=${this.orderData.pay_order_sn}&pay_source=2&pay_type=${way}
							&total_fee=${this.orderData.pay_order_total_amount}
							&notify_url=${process.env.VUE_APP_JPKURL}`;
    },
  },
  created() {
    this.openchated();
    this.callRouter = this.$route.path;
    this.isjy();
  },
  mounted() {
    window["yqt_pay_order_status_get"] = (
      pay_status,
      order_no,
      pay_type,
      phone_type
    ) => {
      // alert(`支付状态${pay_status}，订单编号${order_no}，支付方式${pay_type}，手机客户端${phone_type}`);
      location.reload();
    };
  },
  watch: {
    show_keyboard(val) {
      if (val) {
        this.$emit("noClick");
      } else {
        this.$emit("canClick");
      }
    },
    show_card_pay(val) {
      if (val) {
        this.$emit("noClick");
      } else {
        this.$emit("canClick");
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "../../common/less/mixin.less";
.info-item {
  .sc(0.32rem, #333);
  background: @color-white;
  .wh(100%, 1rem);
  padding: 0 0.24rem;
  margin-top: 0.2rem;

  input::placeholder {
    .sc(0.32rem, @color-light-grey);
  }
}
.pay-sty {
  width: 60%;
  height: 80%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  border: 0;
}
.orderBtn {
  display: flex;
  flex-direction: row-reverse;
  // justify-content: center;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  .btn-item {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    width: 2rem;
    height: 0.68rem;
    border-radius: 0.68rem;
    text-align: center;
    line-height: 0.68rem;
    font-size: 0.28rem;
    box-sizing: border-box;
    background: #fff;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    margin-right: 0.3rem;
  }

  .bg {
    background: var(--main-color);
    color: #fff;
  }
}
// 客服弹窗样式
.padding {
  margin-left: -0.3rem;
}
.PopUp {
  // background-color: red;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  z-index: 999999;
  bottom: 0%;
  height: 2000%;
  flex-shrink: 0;
  // margin-left: -0.3rem;
  .bot-mobile {
    width: 80%;
    // border: 1px solid #fff;
    position: absolute;
    height: 3rem;
    bottom: 4rem;
    left: 0.5rem;
    text-align: center;
    line-height: 80px;
    background-color: #fff;
    margin-left: 0.3rem;
    border-radius: 10px;
    img {
      width: 0.5rem;
      height: 0.5rem;
      float: right;
      margin: 0.2rem;
    }
    .service-phone {
      font-size: 19px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      position: absolute;
      margin-top: 0.5rem;
    }
    .service-phone span {
      color: #000;
    }
    .servicepnone {
      display: inline-block;
      // line-height: 4rem;
      width: 2rem;
      height: 0.5rem;
      font-size: 16px;
      color: #000;
      margin-top: 0.25rem;
    }
    .dial {
      left: 0rem;
      width: 1.5rem;
    }
    .dial,
    .cancel {
      position: absolute !important;
      font-size: 15px;
      border-radius: 0 0 0 10px;
      line-height: 0.8rem !important;
      height: 0.8rem;
      width: 3rem;
      // border: 1px solid #ccc;
      line-height: 0.5rem;
      text-align: center;
      color: #fff;
      top: 2.2rem !important;
      background: var(--main-color);
    }
    .cancel {
      left: 50% !important;
      width: 50%;
      border-radius: 0 0 10px 0;
      background: #f1f1f1;
      color: var(--main-color);
    }
  }
}
</style>
