<template>
  <div class="card-pay">
    <yd-popup v-model="show_card_pay" position="bottom" height="50%">
      <div class="info-item">
        <yd-input slot="right" v-model="card_no" required :show-success-icon="false" :show-error-icon="false"
                  placeholder="请输入兑换卡号"></yd-input>
      </div>
      <div class="info-item">
        <yd-input slot="right" v-model="card_password" required :show-success-icon="false" :show-error-icon="false"
                  placeholder="请输入兑换密码"></yd-input>
      </div>
      <div class="btn-con" @click.stop="cardPayOrder">
        <MyButton title="确定" :is_disabled="false"></MyButton>
      </div>
    </yd-popup>
  </div>
</template>

<script>
import MyButton from '@/components/button/button'

export default {
  name: "card_pay",
  components: {
    MyButton
  },
  props: {
    show_card_pay: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      card_no:'',
      card_password:'',
    }
  },
  methods:{
    cardPayOrder(){
      if (!this.card_no) {
        this.$dialog.toast({
          mes: '请输入卡号',
        });
        return
      }
      if (!this.card_password) {
        this.$dialog.toast({
          mes: '请输入密码',
        });
        return
      }
      let params ={
        card_no: this.card_no,
        card_password: this.card_password,
      };
      this.$emit("cardPayOrder", params);

    },
  }
}
</script>

<style lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.card-pay {
  .yd-popup-content{
    background: #f5f5f5;
  }
  .info-item {
    .sc(0.32rem, #333);
    background: @color-white;
    .wh(100%, 1rem);
    padding: 0 0.24rem;
    margin-top: 0.2rem;

    input::placeholder {
      .sc(0.32rem, @color-light-grey);
    }
  }

  .btn-con {
    margin-top: 0.3rem;
  }
}

</style>
